@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('./assets/fonts/Gilroy-Regular.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./assets/fonts/Gilroy-Medium.ttf');
}
@font-face {
  font-family: 'Gilroy-Semibold';
  src: url('./assets/fonts/Gilroy-Semibold.ttf');
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./assets/fonts/Gilroy-Bold.ttf');
}
@font-face {
  font-family: 'Gilroy-RegularItalic';
  src: url('./assets/fonts/Gilroy-RegularItalic.ttf');
}
@font-face {
  font-family: 'aAbsoluteEmpire';
  src: url('./assets/fonts/aAbsoluteEmpire.ttf');
}


:root {
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  box-sizing: border-box;
  color: #FFFFFF;
  background-color: #080B11;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  background-color: #14181B !important;
  border-bottom: 0 !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  color: #ffffff !important
}

.react-datepicker {
  background-color: #14181B!important;
  border-radius: 16px!important;
  border-color: #222930 !important;
  color: #ffffff !important;
  font-family: 'Gilroy-Bold' !important;
  font-size: 14px !important;
}

.react-datepicker__day {
  color: #ffffff !important
}

.react-datepicker__day-name {
  color: #B5BEC6 !important;
  margin-top: 16px !important;
  font-size: 10px !important;
}

.react-datepicker__current-month {
  color: #ffffff !important
}

.react-datepicker__day--selected {
  background: linear-gradient(to right bottom, #DC343B, #A1090A) !important;
  border-radius: 50% !important;
}

.react-datepicker__day:hover {
  background-color: #B5BEC6 !important;
  border-radius: 50% !important;
}

.react-datepicker-time__header {
  color: #ffffff !important
}

.react-datepicker__time-list {
  background-color: #14181B!important;
}

.react-datepicker__time-list-item:hover {
  background-color: #B5BEC6 !important;
}

.react-datepicker__time-list-item--selected {
  background: linear-gradient(to right bottom, #DC343B, #A1090A) !important;
}

.epr-header {
  opacity: 0;
  height: 0;
}

.epr_-kg0voo {
  opacity: 0;
  height: 0 !important;
}

input[type="file"] {
  display: none;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: rgba(0, 0, 0, 0.1);
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(176, 0, 0, 0.3);
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background:rgba(176, 0, 0, 0.7);
  }
}